import { call, put } from 'redux-saga/effects';
import actionTypes from './actiontypes';

export function* setAddFavMenuAction(data) {
  yield put({ type: actionTypes.SET_ADD_FAV_MENU, payload: data.key });
}

export function* setRemoveFavMenuAction(data) {
  yield put({ type: actionTypes.SET_REMOVE_FAV_MENU, payload: data.key });
}

export function* getIsFavoritedAction(data) {
  yield put({ type: actionTypes.GET_IS_FAVORITED, payload: data.key });
}