import actionTypes from './actiontypes'

const initialState = {
  matches: [],
  matchID: '',
  chartData: {},
  priceData: {},
  data1ht: {},
  data2ht: {},
  summary: [],
  home: 'HOME', away: 'AWAY',
  hsc: 0, asc: 0,
  league: 'LEAGUE',
  hred: 0, ared: 0,
  hyel: 0, ayel: 0,
  hcorner: 0, acorner: 0,
  time: '00:00',
  tf: 0,
  tm: 0,
  ts: 0,
  tt: 0,
  isFT: true,
  chartState: 1,
  teamState: 0,
  limit: 0,
  isChartLoading: false,
  isPriceLoading: false,
  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_MATCH_CHART_STATE:
      return { ...state, chartState: action.data.state }

    case actionTypes.SET_MATCH_CHART_KEY: {
      return { ...state, matchID: action.key }
    }

    case actionTypes.GET_MATCH_DONE:
      return {
        ...state,
        chartData: action.payload,
        summaryData: action.payload.summaryData
      }

    case actionTypes.GET_MATCH_BOARD_DONE:
      return { ...state, ...action.payload }

    case actionTypes.GET_MATCH_BOARD_DIF_DONE:
      if (action.payload.time)
        return Object.assign({}, state, {
          hsc: action.payload.hsc,
          asc: action.payload.asc,
          hred: action.payload.hred,
          ared: action.payload.ared,
          hyel: action.payload.hyel,
          ayel: action.payload.ayel,
          hcorner: action.payload.hcorner,
          acorner: action.payload.acorner,
          time: action.payload.time
        })
      return initialState

    default:
      return state
  }
}
