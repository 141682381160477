const actiontypes = {
  SET_STATE: 'user/SET_STATE',

  LOGIN: 'user/LOGIN',
  LOGIN_TOKEN: 'user/LOGIN_TOKEN',
  LOGIN_DONE: 'user/LOGIN_DONE',
  LOGIN_FAIL: 'user/LOGIN_FAIL',
  LOGIN_REQ: 'user/LOGIN_REQ',

  LOGOUT: 'user/LOGOUT',
  LOGOUT_DONE: 'user/LOGOUT_DONE',
  LOGOUT_REQ: 'user/LOGOUT_REQ',

  USER_LOAD: 'user/USER_LOAD',
  USER_LOAD_FAIL: 'user/USER_LOAD_FAIL',
  USER_LOAD_REQ: 'user/USER_LOAD_REQ',

  REGISTER: 'user/REGISTER',

  LOAD_CURRENT_USER: 'user/LOAD_CURRENT_USER',
  LOAD_CURRENT_USER_DONE: 'user/LOAD_CURRENT_USER_DONE',
  LOAD_CURRENT_USER_FAIL: 'user/LOAD_CURRENT_USER_FAIL',
  LOAD_CURRENT_USER_REQ: 'user/LOAD_CURRENT_USER_REQ',
}


export default actiontypes