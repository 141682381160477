import { useEffect, useState } from "react"
import { connect } from 'react-redux'
import store from 'store'

import actiontypes from "redux/menu/actiontypes"
import actiontypesUser from "redux/user/actiontypes"
import actiontypesLiveForm from "redux/liveform/actiontypes"

import { socket } from "./socket";

const mapStateToProps = ({ menu, user, liveform }) => ({
    isNeedChartUpdate: liveform.isNeedChartUpdate,
    chartHalf: liveform.chartHalf,
    matchList: menu.matchList,
    currentMatchId: menu.currentMatchId,
    isNeedReloadData: menu.isNeedReloadData,

    serverTime: menu.serverTime,
    authorized: user.authorized,
})

const SocketService = ({
    dispatch,
    isNeedChartUpdate = false,
    isNeedReloadData = false,
    chartHalf = -1,
    matchList = [],
    serverTime = 0,
    authorized = false,
    currentMatchId,
}) => {

    const rearrangeMenu = (matches) => {

        let isUnknownLeague = false;
        const leagueSet = new Set();
        for (let i = 0; i < matches.length; i += 1) {
            const { league = "" } = matches[i];
            if (league === "") {
                // console.log('league ', league);
                isUnknownLeague = true;
            }

            const txtLeague = league || "unknown";
            leagueSet.add(txtLeague);
        }

        const leagues = [...leagueSet].sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
        const newMatches = [];
        for (let iLeague = 0; iLeague < leagues.length; iLeague += 1) {

            const childs = [];
            for (let iMatch = 0; iMatch < matches.length; iMatch += 1) {
                const { league = "" } = matches[iMatch];
                const txtLeague = league || "unknown";
                if (txtLeague === leagues[iLeague]) {
                    childs.push(matches[iMatch]);
                    matches.splice(iMatch, 1);
                    iMatch -= 1;
                }
            }

            if (childs.length > 0) {
                childs.sort((a, b) => b.time * 1 - a.time * 1);
            }

            newMatches.push({
                type: 'league',
                league: leagues[iLeague],
                id: leagues[iLeague],
                isFavorite: false,
                childs,
            });
        }

        if (isUnknownLeague) {
            reloadData(5);
        }

        return newMatches;
    }

    useEffect(() => {
        // console.log(authorized);
        if (authorized) {
            socket.disconnect();

            socket.io.opts.extraHeaders = {
                "x-auth-token": store.get('accessToken'),
            }

            socket.connect();

            socket.emit("isExpired", store.get('accessToken'));
        } else {
            socket.disconnect();
        }

        // eslint-disable-next-line
    }, [authorized])

    useEffect(() => {
        dispatch({
            type: actiontypesLiveForm.SET_STATE,
            payload: { isChartLoading: true }
        });
        socket.emit("j", currentMatchId);
        // eslint-disable-next-line
    }, [currentMatchId]);

    useEffect(() => {
        if (isNeedChartUpdate) {
            dispatch({
                type: actiontypesLiveForm.SET_STATE,
                payload: { isChartLoading: true }
            });
            dispatch({ type: actiontypesLiveForm.SET_CHART_SUMMARY, payload: { s: [] } });
            socket.emit("j", currentMatchId);
        }

        // eslint-disable-next-line
    }, [isNeedChartUpdate]);

    useEffect(() => {
        if (isNeedReloadData) {
            // console.log('isNeedReloadData', isNeedReloadData);
            socket.emit("getlist");
        }
        // eslint-disable-next-line
    }, [isNeedReloadData]);

    useEffect(() => {
        if (chartHalf !== -1) {
            // emit load half data
            socket.emit("h", { MD: chartHalf, id: currentMatchId });
        }

        // eslint-disable-next-line
    }, [chartHalf]);

    useEffect(() => {
        function onConnect() {
            // console.log('onConnect', store.get('app.menu.selectedKeys'))
            socket.emit("getlist");
            dispatch({ type: actiontypes.SET_STATE, payload: { isSocketConnected: true } })
            const selectedKeys = store.get('app.menu.selectedKeys') || [];
            if (selectedKeys.length > 0) {
                socket.emit("j", selectedKeys[0]);
            }
        }

        function onDisconnect() {
            dispatch({ type: actiontypes.SET_STATE, payload: { isSocketConnected: false } })
        }

        function onFirstConnect(value) {

            const { matches = [] } = value;
            const newMatches = rearrangeMenu(matches.slice(0));

            dispatch({
                type: actiontypesLiveForm.SET_STATE, payload: {
                    isChartLoading: false,
                    isPriceLoading: false,
                }
            });

            dispatch({ type: actiontypes.SET_STATE, payload: { isNeedReloadData: false } });
            dispatch({ type: actiontypes.GET_TEAM_DONE, payload: { ...value, newMatches } });

            if (matches.length > 0) {
                const selectedKeys = store.get('app.menu.selectedKeys') || [];
                if (selectedKeys.length > 0) {

                    const key = selectedKeys[0];
                    const idxMatch = matches.findIndex(e => e.id === selectedKeys[0])
                    if (idxMatch > -1) {
                        dispatch({ type: actiontypesLiveForm.SET_STATE, payload: matches[idxMatch] });

                        dispatch({ type: actiontypes.GET_IS_FAVORITED_REQ, key });
                        dispatch({ type: actiontypes.SET_STATE, payload: { currentMatchId: key } });

                        return;
                    }
                }

                store.set('app.menu.selectedKeys', [matches[0].id])

                dispatch({ type: actiontypesLiveForm.SET_STATE, payload: matches[0] });
                dispatch({ type: actiontypes.SET_STATE, payload: { currentMatchId: matches[0].id } })
                dispatch({ type: actiontypes.GET_IS_FAVORITED_REQ, key: matches[0].id })
            }


        }

        function onMatchUpdate(value) {
            delete value.id;
            if ('s' in value) {
                dispatch({ type: actiontypesLiveForm.SET_CHART_SUMMARY, payload: { s: value.s } });
                delete value.s;
            }
            dispatch({ type: actiontypesLiveForm.SET_STATE, payload: value });
        }

        function onUpdate(value) {
            dispatch({ type: actiontypes.SET_TEAM_SOCKET_UPDATE, payload: value })
        }

        function onAdd(value) {

            dispatch({ type: actiontypes.SET_TEAM_SOCKET_ADD, payload: value })

            // if no league -> reload in 10 minute
            const { league = "" } = value;
            if (league === "") {
                // console.log('add league', value);
                reloadData(5);
            }
        }

        // Odds
        function onOddsUpdate(value) {
            dispatch({ type: actiontypesLiveForm.SET_STATE, payload: { priceData: value, isPriceUpdate: true } });
            setTimeout(() => {
                dispatch({ type: actiontypesLiveForm.SET_STATE, payload: { isPriceUpdate: false } });
            }, 3 * 1000);
        }

        function onGetOdds(value) {
            dispatch({ type: actiontypesLiveForm.SET_STATE, payload: { priceData: value } });
        }

        function onExpired(value) {
            const { isExp = true, exp = 0 } = value;
            if (isExp) {
                socket.disconnect();
                dispatch({ type: actiontypesUser.LOGOUT });
            } else {
                setTimeout(() => {
                    socket.disconnect();
                    dispatch({ type: actiontypesUser.LOGOUT });
                }, exp * 1000);
            }
        }

        function onChart(value) {
            if (!('ct' in value)) {
                dispatch({
                    type: actiontypesLiveForm.SET_STATE,
                    payload: {
                        chart: {},
                        isNeedChartUpdate: false,
                        isChartLoading: false,
                    }
                });
            }

            const { ct, id, MD } = value;
            dispatch({
                type: actiontypesLiveForm.SET_STATE,
                payload: {
                    chart: { ...ct, id, MD },
                    isNeedChartUpdate: false,
                    isChartLoading: false,
                }
            });
        }

        function onChartAdd(value) {
            dispatch({ type: actiontypesLiveForm.SET_CHART_ADD, payload: value.data });
        }

        function onChartUpdate(value) {
            dispatch({ type: actiontypesLiveForm.SET_CHART_UPDATE, payload: value });
        }

        /*
        socket.on("disconnect", (reason) => {
            console.log(reason); // prints "io client disconnect"
        });
        */

        /*
        socket.io.on("reconnect", (attempt) => {
            socket.emit("getlist");
        });
        */

        socket.on("connect_error", (error) => {
            console.log('connect_error');
            console.log(error.message)
            console.log(error)
        });

        socket.on("error", (error) => {
            console.log('error');
            console.log(error)
        });

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('f', onFirstConnect);
        socket.on('u', onUpdate);
        socket.on('mu', onMatchUpdate);
        socket.on('i', onAdd);

        // odds
        socket.on('ou', onOddsUpdate);
        socket.on('od', onGetOdds);

        // chart
        socket.on('ct', onChart);
        socket.on('cu', onChartUpdate);
        socket.on('ca', onChartAdd);

        socket.on('expired', onExpired);

        return () => {
            socket.offAny();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const timeWait = 3;
        /*
        let timeWait = parseInt((matchList.length * 0.05), 10);
        if (timeWait < 3) {
            timeWait = 3;
        } else if (timeWait > 10) {
            timeWait = 10;
        }
        */
        // console.log('timeWait:', timeWait, 'sec');
        const intv = setInterval(getTime, timeWait * 1000);
        // const intv = setInterval(getTime, 3 * 1000);
        return () => clearInterval(intv)
        // eslint-disable-next-line
    }, [matchList]);

    function getTime() {
        const dNow = parseInt(Date.now() / 1000, 10) + serverTime;

        const matchesTime = [];
        for (let i = 0; i < matchList.length; i += 1) {
            const { TT = 0, TM = 0, TS = 0, TU = 0, id } = matchList[i];

            if (TU > 0) {
                const tmp = Math.abs(dNow - TU) + TS
                const minute = parseInt(tmp / 60, 10);
                const second = tmp - (minute * 60);
                const timeint = TT === 0 ? TM : minute + TM;
                const time = timeint.toString().padStart(2, '0');
                matchesTime.push({ id, time });
            }
        }

        dispatch({ type: actiontypes.SET_TEAM_TIME_UPDATE, payload: matchesTime });
    }

    function reloadData(minutes = 0) {
        // console.log('reload data in ', minutes, ' minutes');
        setTimeout(() => {
            dispatch({ type: actiontypes.SET_STATE, payload: { isNeedReloadData: true } });
        }, minutes * 60 * 1000);
    }

    return null;
}

export default connect(mapStateToProps)(SocketService)