import apiClient from 'services/axios'

export function fetchAllMatchData(data) {
     return apiClient.post(`v2/match/`, { team: data.team })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchMatchData(data) {
     return apiClient.get(`v2/match/${data.matchId}`)
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchTeamData(matchid) {
     return apiClient.post(`v2/live/stat/chart`, { matchid })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchTeamHalfData(data) {
     return apiClient.post(`v2/live/stat/chartht`, { matchid: data.id, ft: data.ft })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchTeamBoard(id) {
     return apiClient.post(`v2/live/match/board`, { id })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchTeamBoardDif(id) {
     return apiClient.post(`v2/live/match/boarddif`, { id })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};

export function fetchPrice(matchid) {
     return apiClient.post(`v2/live/price/pr`, { matchid })
          .then(response => {
               return response.data
          })
          .catch(err => {
               throw Error(err)
          })
};