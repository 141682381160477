const actiontypes = {
     SET_STATE: 'liveform/SET_STATE',

     GET_FORM_SUMMARY_ASYNC_REQ: 'liveform/GET_FORM_SUMMARY_ASYNC_REQ',
     GET_FORM_SUMMARY_DONE: 'liveform/GET_FORM_SUMMARY_DONE',

     GET_FORM_BOARD_ASYNC_REQ: 'liveform/GET_FORM_BOARD_ASYNC_REQ',
     GET_FORM_BOARD_DONE: 'liveform/GET_FORM_BOARD_DONE',
     
     GET_FORM_BOARD_DIF_ASYNC_REQ: 'liveform/GET_FORM_BOARD_DIF_ASYNC_REQ',
     GET_FORM_BOARD_DIF_DONE: 'liveform/GET_FORM_BOARD_DIF_DONE',

     GET_FORM_CHART_ASYNC_REQ: 'liveform/GET_FORM_CHART_ASYNC_REQ',
     GET_FORM_CHART_DONE: 'liveform/GET_FORM_CHART_DONE',

     GET_FORM_CHART_HALF_ASYNC_REQ: 'liveform/GET_FORM_CHART_HALF_ASYNC_REQ',

     SET_FORM_CHART_STATE: 'liveform/SET_FORM_CHART_STATE',
     SET_FORM_CHART_STATE_REQ: 'liveform/SET_FORM_CHART_STATE_REQ',

     SET_CHART_ADD: 'liveform/SET_CHART_ADD',
     SET_CHART_UPDATE: 'liveform/SET_CHART_UPDATE',
     SET_CHART_SUMMARY: 'liveform/SET_CHART_SUMMARY',

     SET_FORM_CHART_KEY: 'liveform/SET_FORM_CHART_KEY',
     SET_FORM_CHART_KEY_REQ: 'liveform/SET_FORM_CHART_KEY_REQ',

     GET_PRICE_DONE: 'liveform/GET_PRICE_DONE',
     GET_PRICE_REQ: 'liveform/GET_PRICE_REQ',
  
}

export default actiontypes