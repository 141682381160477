import { io } from "socket.io-client";
import store from 'store'

const baseURL = 'https://ws.soccer789.com'
// const baseURL = 'ws://127.0.0.1:7101'

export const socket = io(baseURL, {
    extraHeaders: {
        "x-auth-token": store.get('accessToken'),
    },
    autoConnect: false,
    reconnectionDelay: 15 * 1000,
    reconnectionDelayMax: 15 * 1000,

});

export function getHalfSocket(id, md) {
    socket.emit("h", { id, md });
}

export function joinSocket(id) {
    socket.emit("j", id);
}

/*
export function newConnect() {
    console.log("newConnect");
    const accessToken = store.get('accessToken')
    const socket = io(baseURL, {
        extraHeaders: {
            "x-auth-token": accessToken,
        },
        // autoConnect: false,
        // reconnectionAttempts: 1,
        reconnectionDelay: 30 * 1000,
    });

    return socket;
}
*/
/*
export function fetchMatchMenu() {
    socket.emit("first")
}
*/