import store from 'store'
import actionTypes from './actiontypes'
import HomeGoalSound from '../../components/sound/home-notification.mp3'
import AwayGoalSound from '../../components/sound/click-124467.mp3'

const audioHomeGoal = new Audio(HomeGoalSound)
const audioAwayGoal = new Audio(AwayGoalSound)

const initialState = {
  serverTime: 0,
  matchList: [],
  menuData: [],
  matchesWLeague: [],
  matchesNLeague: [],
  favLeagues: [],
  currentMatchId: "",
  isFavorited: false,
  isSocketConnected: false,
  isNeedReloadData: false,
  isShowLeague: typeof store.get('app.menu.isShowLeague') !== 'undefined' ? store.get('app.menu.isShowLeague') : true,
  isScoreAlert: typeof store.get('app.menu.isScoreAlert') !== 'undefined' ? store.get('app.menu.isScoreAlert') : true,
}

function sortLeague(favLeagues, matchesWLeague) {
  const tmps = [];
  for (let i = 0; i < favLeagues.length; i += 1) {
    const idxLeague = matchesWLeague.findIndex(e => e.league === favLeagues[i])
    if (idxLeague > -1) {
      const data = matchesWLeague.splice(idxLeague, 1)[0];
      data.isFavorite = true;
      tmps.push(data);
    } else {
      favLeagues.splice(i, 1);
      i -= 1;
    }
  }

  matchesWLeague.sort((a, b) => a.league.toLowerCase() < b.league.toLowerCase() ? -1 : 1);
  return [...tmps, ...matchesWLeague];
}

export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.GET_IS_FAVORITED: {

      const idxFav = state.menuData.findIndex(e => e.key === 'favorite');
      if (idxFav < 0) {
        return Object.assign({}, state, {
          isFavorited: false
        })
      }

      const matchId = action.payload;
      const dataFav = state.menuData[idxFav].children;
      const idxMatch = dataFav.findIndex(e => e.id === matchId);
      if (idxMatch < 0) {
        return Object.assign({}, state, {
          isFavorited: false
        })
      }

      return Object.assign({}, state, {
        isFavorited: true
      })
    }

    case actionTypes.SET_REMOVE_FAV_MENU: {

      const idxFav = state.menuData.findIndex(e => e.key === 'favorite');
      if (idxFav < 0) {
        return state;
      }

      const matchId = action.payload;
      const dataFav = state.menuData[idxFav].children;
      const idxMatch = dataFav.findIndex(e => e.id === matchId);
      if (idxMatch < 0) {
        return state;
      }

      dataFav.splice(idxMatch, 1);
      state.menuData[idxFav].count = dataFav.length;

      store.set('app.menu.favs', dataFav.map(e => e.id));

      return Object.assign({}, state, {
        menuData: state.menuData.slice(0),
        isFavorited: false
      })
    }

    case actionTypes.SET_ADD_FAV_MENU: {

      const idxFav = state.menuData.findIndex(e => e.key === 'favorite');
      if (idxFav < 0) {
        return state;
      }

      const dataFav = state.menuData[idxFav].children;
      if (dataFav.findIndex(e => e.id === action.payload) > -1) {
        return state
      }

      let dataLive;
      if (state.isShowLeague) {
        for (let i = 0; i < state.matchesWLeague.length; i += 1) {
          const { childs = [] } = state.matchesWLeague[i];
          const idxMatch = childs.findIndex(e => e.id === action.payload);
          if (idxMatch > -1) {
            dataLive = childs[idxMatch];
            break;
          }
        }
      } else {
        dataLive = state.matchesNLeague.find(e => e.id === action.payload);
      }

      dataFav.push(dataLive);

      if (dataFav.length > 0) {
        dataFav.sort((a, b) => b.time * 1 - a.time * 1);
      }

      store.set('app.menu.favs', dataFav.map(e => e.id));

      // state.menuData[idxFav].children = dataFav;
      state.menuData[idxFav].count = dataFav.length;

      return Object.assign({}, state, {
        menuData: state.menuData.slice(0),
        isFavorited: true
      })
    }

    case actionTypes.SET_FAV_LEAUGE: {
      // store.set('app.menu.selectedLeagues', [key.replace('league-', '')])
      const { league = "", flag = false } = action.payload;
      const idx = state.matchesWLeague.findIndex(e => e.league === league);
      if (idx > -1) {
        state.matchesWLeague[idx].isFavorite = flag;
        if (flag) {
          state.favLeagues = [...state.favLeagues, league].sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
        } else {
          const idxFavLeauge = state.favLeagues.findIndex(e => e === league);
          if (idxFavLeauge > -1) {
            state.favLeagues.splice(idxFavLeauge, 1);
          }
        }

        if (state.favLeagues.length > 0) {
          state.matchesWLeague = sortLeague(state.favLeagues, state.matchesWLeague);
        } else {
          state.matchesWLeague.sort((a, b) => a.league.toLowerCase() < b.league.toLowerCase() ? -1 : 1);
        }

        store.set('app.menu.favLeagues', state.favLeagues);

        return {
          ...state,
          favLeagues: state.favLeagues.slice(0),
          matchesWLeague: state.matchesWLeague.slice(0),
        };
      }

      return state;
    }

    case actionTypes.SET_TEAM_TIME_UPDATE: {

      const tmpUpdate = [...action.payload];

      // Favorite
      const idx = state.menuData.findIndex(e => e.key === 'favorite');

      for (let i = 0; i < state.menuData[idx].children.length; i += 1) {
        const tmp = state.menuData[idx].children[i];
        const idxUpdate = tmpUpdate.findIndex(e => e.id === tmp.id);
        if (idxUpdate > -1) {
          if (tmp.time * 1 !== tmpUpdate[idxUpdate].time * 1) {
            state.menuData[idx].children[i].time = tmpUpdate[idxUpdate].time;
          }
        }
      }

      if (state.menuData[idx].children.length > 0) {
        state.menuData[idx].children.sort((a, b) => b.time * 1 - a.time * 1);
      }

      // livecharts
      if (state.isShowLeague) {
        for (let i = 0; i < state.matchesWLeague.length; i += 1) {
          const league = state.matchesWLeague[i];
          for (let j = 0; j < league.childs.length; j += 1) {
            const tmp = league.childs[j];
            const idxUpdate = tmpUpdate.findIndex(e => e.id === tmp.id);
            if (idxUpdate > -1) {
              if (tmp.time * 1 !== tmpUpdate[idxUpdate].time * 1) {
                tmp.time = tmpUpdate[idxUpdate].time;
              }
              tmpUpdate.splice(idxUpdate, 1);
            }
          }

          if (league.childs.length > 0) {
            league.childs.sort((a, b) => b.time * 1 - a.time * 1);
          }
        }

        return Object.assign({}, state, { matchesWLeague: state.matchesWLeague.slice(0) });
      }

      for (let i = 0; i < state.matchesNLeague.length; i += 1) {
        const tmp = state.matchesNLeague[i];
        const idxUpdate = tmpUpdate.findIndex(e => e.id === tmp.id);
        if (idxUpdate > -1) {
          if (tmp.time * 1 !== tmpUpdate[idxUpdate].time * 1) {
            tmp.time = tmpUpdate[idxUpdate].time;
          }
          tmpUpdate.splice(idxUpdate, 1);
        }
      }

      if (state.matchesNLeague.length > 0) {
        state.matchesNLeague.sort((a, b) => b.time * 1 - a.time * 1);
      }

      return Object.assign({}, state, { matchesNLeague: state.matchesNLeague.slice(0) });
    }

    case actionTypes.SET_TEAM_SOCKET_ADD: {
      const updateData = {};

      const idxLive = state.menuData.findIndex(e => e.key === 'livecharts');

      const { id = "" } = action.payload;
      if (state.isShowLeague) {
        const { league = "" } = action.payload;
        const txtLeague = league || "unknown";
        const idxLeague = state.matchesWLeague.findIndex(e => e.league === txtLeague)
        if (idxLeague > -1) {
          const { childs = [] } = state.matchesWLeague[idxLeague];
          childs.push(action.payload);
          childs.sort((a, b) => b.time * 1 - a.time * 1);
        } else {
          // add league , then sort
          const newLeague = {
            type: 'league',
            league: txtLeague,
            id: txtLeague,
            isFavorite: false,
            childs: [action.payload],
          };
          state.matchesWLeague.push(newLeague);
          state.matchesWLeague = sortLeague(state.favLeagues, state.matchesWLeague);
        }

        updateData.matchesWLeague = state.matchesWLeague.slice(0);
      } else {
        state.matchesNLeague.push(action.payload)
        state.matchesNLeague.sort((a, b) => b.time * 1 - a.time * 1);

        updateData.matchesNLeague = state.matchesNLeague.slice(0);
      }

      if (state.matchList.findIndex(e => e.id === id) < 0) {
        const newData = {
          TT: action.payload.TT,
          TM: action.payload.TM,
          TS: action.payload.TS,
          TU: action.payload.TU,
          id: action.payload.id,
        };
        state.matchList.push(newData);
        updateData.matchList = state.matchList.slice(0);

        state.menuData[idxLive].count = state.matchList.length;
        updateData.menuData = state.menuData.slice(0);
      }

      return Object.assign({}, state, updateData);
    }

    case actionTypes.SET_TEAM_SOCKET_UPDATE: {

      const updateData = {};

      const idxLive = state.menuData.findIndex(e => e.key === 'livecharts');
      const idxFav = state.menuData.findIndex(e => e.key === 'favorite');

      const { id = "", isactive } = action.payload;

      const dataFav = state.menuData[idxFav].children;
      let dataLive;
      let idxLeague = -1;
      let idxWMatch = -1;
      if (state.isShowLeague) {
        for (let i = 0; i < state.matchesWLeague.length; i += 1) {
          const { childs = [] } = state.matchesWLeague[i];
          idxWMatch = childs.findIndex(e => e.id === id);
          if (idxWMatch > -1) {
            dataLive = childs[idxWMatch];
            idxLeague = i;
            break;
          }
        }
      } else {
        idxWMatch = state.matchesNLeague.findIndex(e => e.id === id);
        if (idxWMatch > -1) {
          dataLive = state.matchesNLeague[idxWMatch];
        }
      }

      if (!dataLive) {
        return { ...state, isNeedReloadData: true };
      }

      const idxIdFav = dataFav.findIndex(e => e.id === id);

      if ("isactive" in action.payload) {
        if (!isactive) {
          if (dataLive) {
            if (state.isShowLeague) {
              state.matchesWLeague[idxLeague].childs.splice(idxWMatch, 1);
              if (state.matchesWLeague[idxLeague].childs.length < 1) {
                const idxFavLeauge = state.favLeagues.findIndex(e => e === state.matchesWLeague[idxLeague].league);
                if (idxFavLeauge > -1) {
                  state.favLeagues.splice(idxFavLeauge, 1);
                }

                state.matchesWLeague.splice(idxLeague, 1);

                updateData.matchesWLeague = state.matchesWLeague.slice(0);
                updateData.favLeagues = state.favLeagues.slice(0);

                store.set('app.menu.favLeagues', state.favLeagues);
              }

            } else {
              state.matchesNLeague.splice(idxWMatch, 1);
              updateData.matchesNLeague = state.matchesNLeague.slice(0);
            }

          }

          // Fav
          if (idxIdFav > -1) {
            dataFav.splice(idxIdFav, 1);
            state.menuData[idxFav].count = dataFav.length;
            store.set('app.menu.favs', dataFav.map(e => e.id));
          }

          const idxMatch = state.matchList.findIndex(e => e.id === id);
          if (idxMatch > -1) {
            state.matchList.splice(idxMatch, 1);
            updateData.matchList = state.matchList.slice(0);

            state.menuData[idxLive].count = state.matchList.length;
          }

          updateData.menuData = state.menuData.slice(0);
        }
      } else {
        if ("TT" in action.payload) {
          if (action.payload.TT === 0) {
            // reset warning, danger
            action.payload.isWarning = false;
            action.payload.isDanger = false;
          }
        }

        if (state.isShowLeague) {
          state.matchesWLeague[idxLeague].childs[idxWMatch] = { ...dataLive, ...action.payload };

          updateData.matchesWLeague = state.matchesWLeague.slice(0);
        } else {
          state.matchesNLeague[idxWMatch] = { ...dataLive, ...action.payload };
          updateData.matchesNLeague = state.matchesNLeague.slice(0);
        }

        // Fav

        if (idxIdFav > -1) {

          if (state.isScoreAlert) {
            if ("hsc" in action.payload) {
              if (dataFav[idxIdFav].hsc !== action.payload.hsc) {
                audioHomeGoal.play();
              }
            }

            if ("asc" in action.payload) {
              if (dataFav[idxIdFav].asc !== action.payload.asc) {
                audioAwayGoal.play();
              }
            }
          }

          dataFav[idxIdFav] = { ...dataFav[idxIdFav], ...action.payload };
        }
      }

      if ("TT" in action.payload ||
        "TS" in action.payload ||
        "TM" in action.payload ||
        "TU" in action.payload
      ) {
        const idxMatch = state.matchList.findIndex(e => e.id === id);
        if (idxMatch > -1) {
          if (
            (action.payload.TT && action.payload.TT !== state.matchList[idxMatch].TT) ||
            (action.payload.TM && action.payload.TM !== state.matchList[idxMatch].TM) ||
            (action.payload.TU && action.payload.TU !== state.matchList[idxMatch].TU)
          ) {
            state.matchList[idxMatch] = { ...state.matchList[idxMatch], ...action.payload };
            updateData.matchList = state.matchList.slice(0);

          }
        }
      }

      return Object.assign({}, state, updateData);

    }

    case actionTypes.GET_TEAM_DONE: {

      const idxLive = state.menuData.findIndex(e => e.key === 'livecharts');
      const idxFav = state.menuData.findIndex(e => e.key === 'favorite');

      const {
        matches = [],
        newMatches = [],
      } = action.payload;

      if (matches.length > 0) {
        matches.sort((a, b) => b.time * 1 - a.time * 1);

        if (idxFav > -1) {
          const favs = store.get('app.menu.favs') || [];
          for (let i = 0; i < favs.length; i += 1) {
            let idx = state.menuData[idxFav].children.findIndex(e => e.id === favs[i]);
            if (idx < 0) {
              idx = matches.findIndex(e => e.id === favs[i]);
              if (idx > -1) {
                state.menuData[idxFav].children.push(matches[idx]);
                state.menuData[idxFav].count += 1;
              }
            }
          }

          store.set('app.menu.favs', state.menuData[idxFav].children.map(e => e.id));
        }
      }

      state.favLeagues = store.get('app.menu.favLeagues') || [];
      if (state.favLeagues.length > 0) {
        state.matchesWLeague = sortLeague(state.favLeagues, newMatches);
      } else {
        state.matchesWLeague = newMatches;
      }

      state.menuData[idxLive].count = matches.length

      const mList = matches.map(e => ({
        TT: e.TT,
        TM: e.TM,
        TS: e.TS,
        TU: e.TU,
        id: e.id,
      }));

      state.menuData[idxLive].count = mList.length;

      const dNow = parseInt(Date.now() / 1000, 10);
      return Object.assign({}, state, {
        menuData: state.menuData.slice(0),
        matchesWLeague: state.matchesWLeague.slice(0),
        matchesNLeague: matches.slice(0),
        matchList: mList.slice(0),
        favLeagues: state.favLeagues.slice(0),
        serverTime: action.payload.serverTime - dNow,
      })
    }

    case actionTypes.GET_TEAM_DATA_DONE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
