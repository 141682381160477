import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import userActionTypes from '../../redux/user/actiontypes'

const apiClient = axios.create({
  // baseURL: 'http://192.168.1.232/',
  // baseURL: 'http://192.168.1.232:22010/',
  // baseURL: 'http://localhost:22010/',
  // baseURL: 'http://localhost:22010/',
  baseURL: 'https://api.soccer789.com/',
  // baseURL: '/v2/',
  // timeout: 1000,
  // headers: { 'X-Auth-Token': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers = {
      'x-auth-token': accessToken,
      'admin-auth-token': accessToken,
    }
  }

  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  if (response) {
    const { data, status } = response
    //  console.log(data)
    if (status === 400) {
      // check code 
      // if code === 10 -> dispatch clear token
      if (data.code === 10)
        throw data
    }

    if (data) {
      const { msg } = data
      if (msg)
        notification.warning({
          message: msg,
        })
    }
  }
})

export default apiClient
