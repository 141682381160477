import { all, put, call, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actionTypes from './actiontypes'
import { getIsFavoritedAction, setAddFavMenuAction, setRemoveFavMenuAction } from './actions'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE', payload: { menuData, },
  })
}

function* watchSetAddFavMenuAction() {
  yield takeEvery(actionTypes.SET_ADD_FAV_MENU_REQ, setAddFavMenuAction);
}

function* watchSetRemoveFavMenuAction() {
  yield takeEvery(actionTypes.SET_REMOVE_FAV_MENU_REQ, setRemoveFavMenuAction);
}

function* watchGetIsFavoritedAction() {
  yield takeEvery(actionTypes.GET_IS_FAVORITED_REQ, getIsFavoritedAction);
}

export default function* rootSaga() {
  yield all([
    watchSetAddFavMenuAction(),
    watchGetIsFavoritedAction(),
    watchSetRemoveFavMenuAction(),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
