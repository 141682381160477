const actiontypes = {
  SET_STATE: 'menu/SET_STATE',
  GET_DATA: 'menu/GET_DATA',

  SET_ADD_FAV_MENU: 'menu/SET_ADD_FAV_MENU',
  SET_ADD_FAV_MENU_REQ: 'menu/SET_ADD_FAV_MENU_REQ',
  
  SET_REMOVE_FAV_MENU: 'menu/SET_REMOVE_FAV_MENU',
  SET_REMOVE_FAV_MENU_REQ: 'menu/SET_REMOVE_FAV_MENU_REQ',

  SET_FAV_LEAUGE: 'menu/SET_FAV_LEAUGE',
  SET_ADD_FAV_LEAUGE: 'menu/SET_ADD_FAV_LEAUGE',
  SET_REMOVE_FAV_LEAUGE: 'menu/SET_REMOVE_FAV_LEAUGE',

  GET_TEAM_ASYNC: 'menu/GET_TEAM_ASYNC',
  GET_TEAM_ASYNC_REQ: 'menu/GET_TEAM_ASYNC_REQ',

  GET_TEAM_SOCKET_REQ: 'menu/GET_TEAM_SOCKET_REQ',

  SET_TEAM_SOCKET_UPDATE: 'menu/SET_TEAM_SOCKET_UPDATE',
  SET_TEAM_SOCKET_ADD: 'menu/SET_TEAM_SOCKET_ADD',

  SET_TEAM_TIME_UPDATE: 'menu/SET_TEAM_TIME_UPDATE',

  GET_TEAM_DATA_ASYNC_REQ: 'menu/GET_TEAM_DATA_ASYNC_REQ',
  GET_TEAM_DATA_DONE: 'menu/GET_TEAM_DATA_DONE',

  GET_TEAM_DONE: 'menu/GET_TEAM_DONE',
  GET_TEAM_FAILED: 'menu/GET_TEAM_FAILED',

  GET_IS_FAVORITED: 'menu/GET_IS_FAVORITED',
  GET_IS_FAVORITED_REQ: 'menu/GET_IS_FAVORITED_REQ',
}

export default actiontypes