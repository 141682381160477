import actionTypes from './actiontypes'

const initialState = {
  chartData: {},
  chart: {},
  isNeedChartUpdate: false,
  priceData: {},
  summaryData: [],
  chartHalf: -1,
  home: 'HOME',
  away: 'AWAY',
  hsc: 0,
  asc: 0,
  league: 'LEAGUE',
  hred: 0,
  ared: 0,
  hyel: 0,
  ayel: 0,
  hcorner: 0,
  acorner: 0,
  time: '00:00',
  TU: 0,
  TM: 0,
  TS: 0,
  TT: 0,
  MD: 0,
  ML: 90,
  isFT: false,
  chartState: 1,
  teamState: 0,
  isChartLoading: false,
  isPriceLoading: false,
  isLoading: false,
  isDim: true,
  isZero: true,
  isChartAnimation: true,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_FORM_CHART_STATE:
      return { ...state, chartState: action.data.state }

    case actionTypes.GET_FORM_CHART_DONE: {
      return {
        ...state,
        chartData: action.payload,
        summaryData: action.payload.summaryData
      }
    }

    case actionTypes.SET_CHART_SUMMARY: {
      return {
        ...state,
        chart: {
          ...state.chart,
          ...action.payload,
        }
      }
    }

    case actionTypes.SET_CHART_ADD: {
      const data = pushNewData(JSON.parse(JSON.stringify(state.chart)), action.payload)
      return { ...state, chart: data }
    }

    case actionTypes.SET_CHART_UPDATE: {
      const { data: dataUpdate, i: idx } = action.payload
      if ('ts' in state.chart) {
        const len = state.chart.ts.length;
        if (len <= idx) {
          return { ...state, isNeedChartUpdate: true };
        }

        const data = updateStatData(JSON.parse(JSON.stringify(state.chart)), dataUpdate, idx);
        return { ...state, chart: data }
      }

      return state;
    }

    default:
      return state
  }
}

function pushNewData(obj, data) {

  obj.ts.push(data.t);

  obj.das.push(data.d);
  obj.dahs.push(data.dh);
  obj.daas.push(data.da);

  obj.ats.push(data.a);
  obj.aths.push(data.ah);
  obj.atas.push(data.aa);

  obj.onhs.push(data.oh);
  obj.onas.push(data.oa);

  obj.offhs.push(data.fh);
  obj.offas.push(data.fa);

  if ('schs' in data)
    obj.bar.schs.push(data.schs);
  if ('scas' in data)
    obj.bar.scas.push(data.scas);

  if ('yelhs' in data)
    obj.bar.yelhs.push(data.yelhs);
  if ('yelas' in data)
    obj.bar.yelas.push(data.yelas);

  if ('redhs' in data)
    obj.bar.redhs.push(data.redhs);
  if ('redas' in data)
    obj.bar.redas.push(data.redas);

  return obj;
}

function updateStatData(obj, data, idx) {

  obj.das[idx] = data.d;
  obj.dahs[idx] = data.dh;
  obj.daas[idx] = data.da;

  obj.ats[idx] = data.a;
  obj.aths[idx] = data.ah;
  obj.atas[idx] = data.aa;

  obj.onhs[idx] = data.oh;
  obj.onas[idx] = data.oa;

  obj.offhs[idx] = data.fh;
  obj.offas[idx] = data.fa;

  if ('schs' in data)
    obj.bar.schs.push(data.schs);
  if ('scas' in data)
    obj.bar.scas.push(data.scas);

  if ('yelhs' in data)
    obj.bar.yelhs.push(data.yelhs);
  if ('yelas' in data)
    obj.bar.yelas.push(data.yelas);

  if ('redhs' in data)
    obj.bar.redhs.push(data.redhs);
  if ('redas' in data)
    obj.bar.redas.push(data.redas);

  return obj;
}