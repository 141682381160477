import store from 'store'

export default async function getMenuData() {
  return [
    /* {
      title: 'Match',
      key: 'matchs',
      icon: 'fa fa-history',
      url: '/dashboard/matchform',
      roles: [
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    */
    /*
    {
      category: true,
      title: 'Charts',
    },
    */
    {
      title: 'Favorite',
      key: 'favorite',
      icon: 'fe fe-star',
      url: '/dashboard/liveform',
      count: 0,
      children: [],
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      title: 'Live Chart',
      key: 'livecharts',
      icon: 'fe fe-bar-chart-2',
      url: '/dashboard/liveform',
      count: 0,
      children: [],
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
    {
      title: 'SETTING',
      key: 'setting',
      icon: 'fa fa-gear',
      url: '/setting/chart',
      roles: [
        'Chart',
        'Chart Plus',
        'Live chart',
        'Live chart plus',
        'Self Engine',
        'Full Engine',
        'Moderator',
        'Administrator',
        'God',
      ],
    },
  ]
}
