const actiontypes = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SET_PRIMARY_COLOR: 'settings/SET_PRIMARY_COLOR',
  SET_THEME: 'settings/SET_THEME',

  SET_INC_CHART_FONT_SIZE: 'settings/SET_INC_CHART_FONT_SIZE',
  SET_INC_CHART_FONT_SIZE_REQ: 'settings/SET_INC_CHART_FONT_SIZE_REQ',

  SET_DEC_CHART_FONT_SIZE: 'settings/SET_DEC_CHART_FONT_SIZE',
  SET_DEC_CHART_FONT_SIZE_REQ: 'settings/SET_DEC_CHART_FONT_SIZE_REQ',

  SET_ZERO_START: 'settings/SET_ZERO_START',
  SET_ZERO_START_REQ: 'settings/SET_ZERO_START_REQ',
}

export default actiontypes