const actiontypes = {
     SET_STATE: 'match/SET_STATE',

     GET_MATCH_SUMMARY_ASYNC_REQ: 'match/GET_MATCH_SUMMARY_ASYNC_REQ',
     GET_MATCH_SUMMARY_DONE: 'match/GET_MATCH_SUMMARY_DONE',

     GET_MATCH_BOARD_ASYNC_REQ: 'match/GET_MATCH_BOARD_ASYNC_REQ',
     GET_MATCH_BOARD_DONE: 'match/GET_MATCH_BOARD_DONE',
     
     GET_MATCH_BOARD_DIF_ASYNC_REQ: 'match/GET_MATCH_BOARD_DIF_ASYNC_REQ',
     GET_MATCH_BOARD_DIF_DONE: 'match/GET_MATCH_BOARD_DIF_DONE',

     GET_MATCH_CHART_ASYNC_REQ: 'match/GET_MATCH_CHART_ASYNC_REQ',
     GET_MATCH_CHART_DONE: 'match/GET_MATCH_CHART_DONE',

     GET_MATCH_CHART_HALF_ASYNC_REQ: 'match/GET_MATCH_CHART_HALF_ASYNC_REQ',

     SET_MATCH_CHART_STATE: 'match/SET_MATCH_CHART_STATE',
     SET_MATCH_CHART_STATE_REQ: 'match/SET_MATCH_CHART_STATE_REQ',

     SET_MATCH_CHART_KEY: 'match/SET_MATCH_CHART_KEY',
     SET_MATCH_CHART_KEY_REQ: 'match/SET_MATCH_CHART_KEY_REQ',

     GET_FIND_MATCH_REQ: 'match/GET_FIND_MATCH_REQ',
     GET_FIND_MATCH_DONE: 'match/GET_FIND_MATCH_DONE',

     GET_MATCH_REQ: 'match/GET_MATCH_REQ',
     GET_MATCH_DONE: 'match/GET_MATCH_DONE',

     GET_PRICE_DONE: 'match/GET_PRICE_DONE',
     GET_PRICE_REQ: 'match/GET_PRICE_REQ',
}

export default actiontypes