import { call, put } from 'redux-saga/effects';
import { fetchTeamBoard, fetchTeamHalfData, fetchAllMatchData, fetchMatchData } from 'services/api'
import actionTypes from './actiontypes';

export function* getFindMatchAction(data) {
  if (!data)
    return
  try {
    const payload = yield call(fetchAllMatchData, data);
    yield put({ type: actionTypes.SET_STATE, payload: { matches: payload } });
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  }
}

export function* getMatchChartAction(data) {
  if (!data)
    return
  try {
    const payload = yield call(fetchMatchData, data);
    yield put({ type: actionTypes.SET_STATE, payload });
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  }
}

export function* getFormChartHalfAction(data) {
  if (!data)
    return
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: true } });
    const payload = yield call(fetchTeamHalfData, data);
    yield put({ type: actionTypes.GET_FORM_CHART_DONE, payload });
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isChartLoading: false } });
  }
}

export function* getFormBoardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  if (!data)
    return
  try {
    const payload = yield call(fetchTeamBoard, data.key);
    yield put({ type: actionTypes.GET_FORM_BOARD_DONE, payload });
  } catch (e) { }
}

export function* setFormChartKeyAction(data) {
  yield put({ type: actionTypes.SET_FORM_CHART_KEY, data });
}

export function* setFormChartStateAction(data) {
  yield put({ type: actionTypes.SET_FORM_CHART_STATE, data });
}