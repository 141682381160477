import { all, takeLatest } from 'redux-saga/effects'
import actiontypes from './actiontypes'
import { getFindMatchAction, getMatchChartAction } from './actions';

function* watchFindMatchAction() {
  yield takeLatest(actiontypes.GET_FIND_MATCH_REQ, getFindMatchAction);
}

function* watchGetMatchAction() {
  yield takeLatest(actiontypes.GET_MATCH_REQ, getMatchChartAction);
}

export default function* rootSaga() {
  yield all([
    watchFindMatchAction(),
    watchGetMatchAction(),
  ])
}
