import { call, put } from 'redux-saga/effects';
import { fetchTeamBoard, fetchTeamBoardDif, fetchPrice } from 'services/api'
import actionTypes from './actiontypes';

export function* getFormBoardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  if (!data)
    return
  try {
    const payload = yield call(fetchTeamBoard, data.key);
    yield put({ type: actionTypes.GET_FORM_BOARD_DONE, payload });
  } catch (e) { }
}

export function* getFormBoardDifAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  if (!data)
    return
  try {
    const payload = yield call(fetchTeamBoardDif, data.key);
    yield put({ type: actionTypes.SET_STATE, payload });
  } catch (e) { }
}

export function* setFormChartKeyAction(data) {
  yield put({ type: actionTypes.SET_FORM_CHART_KEY, data });
}

export function* setFormChartStateAction(data) {
  yield put({ type: actionTypes.SET_FORM_CHART_STATE, data });
}

export function* getPriceAction(data) {
  if (!data)
    return
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isPriceLoading: true } });
    const payload = yield call(fetchPrice, data.key);
    yield put({ type: actionTypes.SET_STATE, payload: { priceData: payload, }, });
    yield put({ type: actionTypes.SET_STATE, payload: { isPriceLoading: false } });
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isPriceLoading: false } });
  }
}