import { all, takeEvery } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { LOAD_CURRENT_USER, LOGIN, LOGIN_TOKEN, LOGOUT, REGISTER } from './actions'

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.LOGIN, LOGIN),
    takeEvery(actionTypes.REGISTER, REGISTER),
    takeEvery(actionTypes.LOAD_CURRENT_USER, LOAD_CURRENT_USER),
    takeEvery(actionTypes.LOGOUT, LOGOUT),
    takeEvery(actionTypes.LOGIN_TOKEN, LOGIN_TOKEN),
    LOAD_CURRENT_USER(), // run once on app load to check user auth
  ])
}
