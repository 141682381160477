import { put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
/* import * as firebase from 'services/firebase' */
import * as jwt from 'services/jwt'
import actionTypes from './actiontypes';
import menuActionType from '../menu/actiontypes'

const mapAuthProviders = {
  /* firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  }, */
  jwt: {
    login: jwt.login,
    loginToken: jwt.loginToken,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOAD_CURRENT_USER() {
  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, username: name, email, country, role, expired, type } = response.user
    yield put({
      type: actionTypes.SET_STATE,
      payload: {
        id,
        name,
        email,
        country,
        role,
        type,
        expired,
        authorized: true,
      },
    })

    // yield put({ type: menuActionType.GET_TEAM_ASYNC_REQ })
    // yield put({ type: menuActionType.GET_TEAM_SOCKET_REQ })
  }
  else {
    yield put({
      type: actionTypes.SET_STATE,
      payload: {
        id: '',
        name: '',
        role: '',
        type: '',
        email: '',
        country: '',
        expired: 0,
        // authorized: true,
        authorized: false,
      },
    })

  }

  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].login, username, password)
  if (success) {
    yield put({
      type: actionTypes.LOAD_CURRENT_USER,
    })
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: actionTypes.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGIN_TOKEN({ payload }) {
  const { token } = payload
  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].loginToken, token)
  if (success) {
    yield put({
      type: actionTypes.LOAD_CURRENT_USER,
    })
    yield history.push('/')
  }
  if (!success) {
    yield put({
      type: actionTypes.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      id: '',
      name: '',
      role: '',
      type: '',
      email: '',
      country: '',
      expired: 0,
      authorized: false,
      loading: false,
    },
  })
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: actionTypes.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: actionTypes.LOAD_CURRENT_USER,
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: actionTypes.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}