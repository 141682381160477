import { put } from 'redux-saga/effects';
import actionTypes from './actiontypes';

export function* setIncChartFontSizeAction() {
  yield put({ type: actionTypes.SET_INC_CHART_FONT_SIZE });
}

export function* setDecChartFontSizeAction() {
  yield put({ type: actionTypes.SET_DEC_CHART_FONT_SIZE });
}

export function* setZeroStartAction(data) {
  yield put({ type: actionTypes.SET_ZERO_START, payload: data.state });
}