import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout, Badge, Checkbox, Input } from 'antd'
import { SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'

import style from './style.module.scss'

import actionTypesMenu from '../../../../../redux/menu/actiontypes'
import actionTypesLiveForm from '../../../../../redux/liveform/actiontypes'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  matchesWLeague: menu.matchesWLeague,
  matchesNLeague: menu.matchesNLeague,
  searchText: menu.searchText,
  favLeagues: menu.favLeagues,
  isShowLeague: menu.isShowLeague,
  isSocketConnected: menu.isSocketConnected,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  /* logo: settings.logo, */
  role: user.role,
})

/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
const MenuLeft = ({
  dispatch,
  menuData = [],
  matchesWLeague = [],
  matchesNLeague = [],
  searchText = "",
  favLeagues = [],
  isShowLeague = true,
  location: { pathname },

  isSocketConnected = false,
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  /* logo, */
  role,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const [searchTxt, setSearchTxt] = useState("");

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const onChangeCheckLeague = e => {
    store.set('app.menu.isShowLeague', e.target.checked);
    dispatch({
      type: actionTypesMenu.SET_STATE,
      payload: {
        isShowLeague: e.target.checked,
        isNeedReloadData: true
      }
    });
  }

  const onSearch = e => {
    setSearchTxt(e.target.value)
  }

  const handleClick = e => {
    const { key = "" } = e;
    if (key.includes('league-')) {
      const league = key.replace('league-', '');
      const idx = favLeagues.findIndex(ee => ee === league)
      if (idx < 0) {
        dispatch({ type: actionTypesMenu.SET_FAV_LEAUGE, payload: { league, flag: true } });
      } else {
        dispatch({ type: actionTypesMenu.SET_FAV_LEAUGE, payload: { league, flag: false } });
      }
      return;
    }

    store.set('app.menu.selectedKeys', [e.key])

    if (isShowLeague) {
      for (let i = 0; i < matchesWLeague.length; i += 1) {
        const { childs = [] } = matchesWLeague[i];
        const obj = childs.find(ee => ee.id === e.key);
        if (obj) {
          dispatch({ type: actionTypesLiveForm.SET_STATE, payload: obj });
          break;
        }
      }
    } else {
      const obj = matchesNLeague.find(ee => ee.id === e.key);
      if (obj) {
        dispatch({ type: actionTypesLiveForm.SET_STATE, payload: obj });
      }
    }

    setSelectedKeys([e.key]);
    dispatch({ type: actionTypesMenu.SET_STATE, payload: { currentMatchId: e.key } })
    dispatch({ type: actionTypesMenu.GET_IS_FAVORITED_REQ, key: e.key })
  }

  const generateMenuItems = () => {


    const generateMatchMenu = item => {
      const { type = "", id = "" } = item;
      if (isShowLeague) {
        if (type === "league") {
          const { league = "", childs = [], isFavorite = false } = item;
          /* 
            <Menu.ItemGroup key={id} title={league.substring(0, 25)} onClick={() => console.log('click')}>
            </Menu.ItemGroup> 
          */
          return (
            <React.Fragment key={id + league}>
              <Menu.Item key={`league-${id}`} id={style.leagueHeader}>
                {isFavorite ?
                  <StarFilled className={style.favLeague} />
                  :
                  <StarOutlined />
                }
                {league}
              </Menu.Item>
              {childs.map(menuItem => generateMatchMenu(menuItem))}
            </React.Fragment>
          )
        }
      }

      const { isRed, isDanger, isWarning, isHomeScore, isAwayScore, hsc: homeScore, asc: awayScore, home: homeTeam = "", away: awayTeam = "", time: matchTime = "00" } = item;

      if (searchTxt !== "") {
        if (!(homeTeam.toLowerCase().includes(searchTxt.toLowerCase()) || awayTeam.toLowerCase().includes(searchTxt.toLowerCase()))) {
          return null;
        }
      }

      return (
        <Menu.Item key={id} className={[isRed ? `${style.redCard}` : `${style.noCard}`, isDanger ? `${style.stateDanger}` : isWarning ? `${style.stateWarning}` : ""]}>
          <Link to='/dashboard/liveform'>
            <span className={[isHomeScore ? `${style.scoreoccur}` : style.menuScoreDefault]}>{homeScore}</span>
            <span className={style.menuScoreDash}> - </span>
            <span className={[isAwayScore ? `${style.scoreoccur}` : style.menuScoreDefault]}>{awayScore}</span>
            <span className={style.menuScoreTime}> {matchTime} </span>
            <span className={style.menuScoreSeparator}> | </span>
            <span className={style.title}>{homeTeam.substring(0, 16)}</span>
          </Link>
        </Menu.Item>
      )
    }

    const generateItem = item => {

      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }

      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </Link>
            )}
          </Menu.Item>
        )
      }

      const { type = "", id = "" } = item;
      if (isShowLeague) {
        if (type === "league") {
          const { league = "", childs = [] } = item;
          return (
            <Menu.ItemGroup key={id} title={league.substring(0, 25)}>
              {childs.map(menuItem => generateItem(menuItem))}
            </Menu.ItemGroup>
          )
        }
      }

      if (id !== "") {
        const { isRed, isDanger, isWarning, isHomeScore, isAwayScore, hsc: homeScore, asc: awayScore, home: homeTeam, time: matchTime = "00" } = item;
        return (
          <Menu.Item key={id} disabled={disabled} className={[isRed ? `${style.redCard}` : `${style.noCard}`, isDanger ? `${style.stateDanger}` : isWarning ? `${style.stateWarning}` : ""]}>
            {item.target && (-
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-gold ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to='/dashboard/liveform'>
                <span className={[isHomeScore ? `${style.scoreoccur}` : style.menuScoreDefault]}>{homeScore}</span>
                <span className={style.menuScoreDash}> - </span>
                <span className={[isAwayScore ? `${style.scoreoccur}` : style.menuScoreDefault]}>{awayScore}</span>
                <span className={style.menuScoreTime}> {matchTime} </span>
                <span className={style.menuScoreSeparator}> | </span>
                <span className={style.title}>{homeTeam.substring(0, 16)}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }

      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-gold ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-gold ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }

      if (menuItem.children) {
        const badgeCount = menuItem.count ? <span className="badge badge-gold ml-2">{menuItem.count}</span> : ''
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {badgeCount}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )

        if (menuItem.key === 'livecharts') {
          const items = isShowLeague ? matchesWLeague : matchesNLeague;
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {items.map(item => generateMatchMenu(item))}
            </Menu.SubMenu>
          )
        }

        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }

      return generateItem(menuItem)
    })
  }

  /* const menuSettings = isMobileView
    ? {
                width: leftMenuWidth,
              collapsible: false,
              collapsed: false,
              onCollapse,
      }
              : {
                width: leftMenuWidth,
              collapsible: true,
              collapsed: isMenuCollapsed,
              onCollapse,
              breakpoint: 'lg',
      }
              */
  const menuSettings = {
    width: leftMenuWidth,
    collapsible: false,
    collapsed: false, // isMenuCollapsed,
    onCollapse,
    breakpoint: 'lg',
  }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          // width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          width: 256,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 135px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            {/* <img src="resources/images/logo.svg" className="mr-2" alt="Clean UI" /> */}
            <div className={style.name}>SOCCER</div>
            <div className={style.descr}>789</div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
        <hr style={{ margin: '0px', borderTop: '1px solid #1c1d1efc' }} />
        <div className={style.buttomText}>
          <Input placeholder="Search" onChange={onSearch} suffix={<SearchOutlined style={{ color: "#595f65" }} />} />
        </div>
        <div className={style.buttomText}>
          <Badge
            status='processing'
            color={isSocketConnected ? 'green' : 'red'}
            text={<span className={style.footerStatus}>{isSocketConnected ? 'Connected' : 'Disconnected'}</span>}
          />
          <Checkbox checked={isShowLeague} onChange={onChangeCheckLeague}>
            <span className={style.footerStatus}>league</span>
          </Checkbox>
        </div>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
