import store from 'store'
import actionsTypes from './actiontypes'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    authProvider: 'jwt', // firebase, jwt
    logo: 'SOCCER789',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    menuColor: 'dark', // white, dark, gray
    theme: 'dark', // default, dark
    authPagesColor: 'white', // white, gray, image
    primaryColor: '#ECC762',
    leftMenuWidth: 256,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: false,
    isSquaredBorders: false,
    isBorderless: true,
    chartFontSize: 8,
    isChartAnimation: true,
    isZero: true,
    isDim: true,
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionsTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionsTypes.SET_INC_CHART_FONT_SIZE:
      return Object.assign({}, state, {
        chartFontSize: state.chartFontSize + 1
      })

    case actionsTypes.SET_DEC_CHART_FONT_SIZE:
      return Object.assign({}, state, {
        chartFontSize: state.chartFontSize - 1
      })

    case actionsTypes.SET_ZERO_START:
      return Object.assign({}, state, {
        isZeroStart: action.payload
      })
    default:
      return state
  }
}
