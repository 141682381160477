import { all, takeLatest } from 'redux-saga/effects'
import actiontypes from './actiontypes'
import { setFormChartKeyAction, setFormChartStateAction } from './actions';

function* watchSetFormChartStateAction() {
  yield takeLatest(actiontypes.SET_FORM_CHART_STATE_REQ, setFormChartStateAction);
}

function* watchSetFormChartKeyAction() {
  yield takeLatest(actiontypes.SET_FORM_CHART_KEY_REQ, setFormChartKeyAction);
}

export default function* rootSaga() {
  yield all([
    watchSetFormChartKeyAction(),
    watchSetFormChartStateAction(),
  ])
}
